import {ReactNode, useState} from "react";

type Tab = {
    tab: string;
    content: ReactNode;
};

export default function Tabs({tabs}: {tabs: Tab[]}) {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    return <div id="tabs-container">
        <div className="tabs">
            {tabs.map((tab, index) => (
                <button
                    key={index}
                    className={`tab ${index === activeTabIndex ? 'active' : ''}`}
                    onClick={() => setActiveTabIndex(index)}
                >
                    {tab.tab}
                </button>
            ))}
        </div>
        <div className="content">
            {tabs[activeTabIndex].content}
        </div>
    </div>
}
