import axios from "axios";
import {Legend, PlayerRanked, PlayerStats} from "../types/legends.type";

// Create an axios instance configured for the Brawlhalla API.
const brawlhalla = axios.create({
    baseURL: '/api/v1',
    timeout: 8000,
});

/**
 * Retrieves all legends from the Brawlhalla API.
 * @returns {Promise<Legend[]>} A promise that resolves to an array of Legend objects.
 */
export async function getAllLegends(): Promise<Legend[]> {
    try {
        const response = await brawlhalla.get<Legend[]>('/legend');
        return response.data.map(legend => ({
            ...legend,
            strength: +legend.strength,
            dexterity: +legend.dexterity,
            defense: +legend.defense,
            speed: +legend.speed,
        }));
    } catch (error) {
        console.error("Failed to fetch all legends:", error);
        throw error;
    }
}

/**
 * Retrieves ranked data for a player by their Brawlhalla ID.
 * @param {number} BrawlhallaId - The Brawlhalla ID of the player.
 * @returns {Promise<PlayerRanked>} A promise that resolves to the PlayerRanked data.
 */
export async function getRankedById(BrawlhallaId: number): Promise<PlayerRanked> {
    try {
        const response = await brawlhalla.get<PlayerRanked>('/player/ranked/'+BrawlhallaId);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch ranked data by ID: ${BrawlhallaId}`, error);
        throw error;
    }
}

/**
 * Retrieves Brawlhalla id for a player by their Steam vanity url.
 * @param {string} steamVanityUrl - The Steam vanity url username 3-17 alphanumeric.
 * @returns {String} - The Brawlhalla id.
 */
export async function getBrawlhallaId(steamVanityUrl: string): Promise<string> {
    try {
        const response = await brawlhalla.get<string>('/player/brawlhallaId/'+steamVanityUrl);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch Brawlhalla id by Steam vanity url: ${steamVanityUrl}`, error);
        throw error;
    }
}

/**
 * Retrieves player statistics by their Brawlhalla ID.
 * @param {number} BrawlhallaId - The Brawlhalla ID of the player.
 * @returns {Promise<PlayerStats>} A promise that resolves to the PlayerStats data.
 */
export async function getPlayerStatsById(BrawlhallaId: number): Promise<PlayerStats> {
    try {
        const response = await brawlhalla.get<PlayerStats>('/player/stats/'+BrawlhallaId);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch player stats by ID: ${BrawlhallaId}`, error);
        throw error;
    }
}
