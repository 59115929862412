import React, {ReactNode} from "react";

interface UserStatProps {
    label: string,
    value?: string | number
    children?: ReactNode
}

export default function UserStat({ label, value, children }: UserStatProps) {
    return (
        <div id="user-stat">
            <div className="label">{label}</div>
            <div className="value">{value||children}</div>
        </div>
    );
}
