import React, {useEffect} from "react";
import {Typography} from "antd";
import {useParams} from "react-router-dom";
import {useDocumentTitle, useFetch, useLocalStorage} from "@mralbelo/react-hooks";
import UserStat from "../shared/components/UserStat";
import {Player, PlayerLegend} from "../shared/types/player.type";
import Tabs from "../shared/components/Tabs";
import {RecentlySearchedPlayer} from "../shared/types/local-storage.type";
import RankedBanner from "../shared/components/RankedBanner";
import {useDispatch} from "react-redux";
import {setLoading} from "../shared/store/reducers/loading.reducer";
import {millisToFriendlyDuration} from "../shared/utils/format";

export default function PlayerStatsDetailsPage() {
    const {brawlhallaId} = useParams<{ brawlhallaId?: string }>();
    const {data: player, loading, error} = useFetch<Player>('/api/v1/player/' + brawlhallaId)
    useDocumentTitle(player ? `Brawlverse: ${player.name}'s Stats` : "Brawlverse", false, [player]);
    const dispatch = useDispatch();
    const [recentPlayers, setRecentPlayers] = useLocalStorage<{ [key: number]: RecentlySearchedPlayer }>(
        "recently-searched-players", {}
    );

    useEffect(() => {
        dispatch(setLoading(true));
        if (player) {
            setRecentPlayers(
                Object.assign(recentPlayers, {
                    [player.id]: {
                        id: player.id,
                        name: player.name,
                        timestamp: Date.now()
                    }
                })
            );
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [player]);

    if (loading) return <></>;
    if (error || !player) return <Typography>Error fetching data or data not available.</Typography>;

    return (
        <>
            <div id="player-stats-details">
                <div className="player">
                    <div className="info">
                        <div className="title">{player.name}</div>
                        <div className="subtitle">LVL {player.level} • {(player.playtime / 3.6e+6).toFixed()}h
                            • {player.region}</div>
                    </div>
                    <div className="rating">
                        <UserStat label={player.tier} value={player.rating}></UserStat>
                        <RankedBanner rating={player.rating}/>
                    </div>
                </div>

                <Tabs
                    tabs={[
                        {
                            tab: "Overview",
                            content: PlayerOverview(player)
                        },
                        {
                            tab: "Legends",
                            content: PlayerLegends(player)
                        },
                        // {
                        //     tab: "Assets",
                        //     content: <>Assets test</>
                        // }
                    ]}
                />
            </div>
        </>
    );
}

function PlayerOverview(player: Player): React.JSX.Element {
    const {lifetimeStats: lifetime, rankedStats: ranked} = player;

    return <>
        <div className="player-stats">
            <div className="title">Lifetime Stats</div>
            <div className="content">
                <UserStat label="Wins" value={lifetime.wins}/>
                <UserStat label="Win %" value={((lifetime.wins / lifetime.games) * 100).toFixed() + "%"}/>
                <UserStat label="K/D Ratio" value={(lifetime.kills / lifetime.deaths).toFixed(1)}/>
                <UserStat label="Kills" value={(lifetime.kills || 0).toString()}/>
                <UserStat label="Deaths" value={(lifetime.deaths || 0).toString()}/>
                <UserStat label="Avg Duration" value={millisToFriendlyDuration(lifetime.avgDuration)}/>
                <UserStat label="Team Kills" value={lifetime.teamKills.toString()}/>
                <UserStat label="Hits Snowball" value={(lifetime.hitSnowballs || 0).toString()}/>
                <UserStat label="XP" value={(lifetime.xp / 1000).toFixed(2) + 'K'}/>
            </div>
        </div>
        <div className="player-stats">
            <div className="title">Ranked Stats</div>
            <div className="content">
                <UserStat label="Peak Rating" value={ranked.peakRating}/>
                <UserStat label="Matches" value={ranked.matches}/>
                <UserStat label="Wins" value={ranked.wins}/>
                <UserStat label="Win %" value={((ranked.wins / ranked.matches) * 100).toFixed() + "%"}/>
            </div>
        </div>
    </>
}

function PlayerLegends(player: Player): React.JSX.Element {

    function getImageUrl(legend: PlayerLegend): string {
        const image = legend.name
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join('_');
        return `/images/legends/${image}.png`
    }

    return <div className="player-legends">
        {
            player.legends.sort((a, b) => a.level - b.level).reverse().map(legend => {

                return <div className="legend" key={legend.id}>
                    <div className="image">
                        <img src={getImageUrl(legend)} alt={legend.name}/>
                    </div>
                    <div className="legend-info">
                        <div className="info">
                            <div className="title">{legend.name}</div>
                            <div className="subtitle">LVL {legend.level} • {(legend.matchtime/3600).toFixed()}H</div>
                        </div>
                        {
                            (legend.rating > 0) &&
                            <div className="rating">
                                <UserStat label={legend.tier} value={legend.rating}></UserStat>
                                <RankedBanner rating={legend.rating}/>
                            </div>
                        }
                    </div>

                    <div className="progress-bar">
                        <div className="content">
                            <div>{legend.wins} W</div>
                            <div>{((legend.wins/(legend.loss+legend.wins)) * 100).toFixed()}%</div>
                            <div>{legend.loss} L</div>
                        </div>
                        <div className="bar">
                            <div style={{width: (legend.wins/(legend.loss+legend.wins)) * 100+'%'}} />
                        </div>
                    </div>
                    <div className="legend-stats">
                        <UserStat label="K/D Ratio" value={(legend.kills / legend.deaths).toFixed(1)}></UserStat>
                        <UserStat label="Playtime" value={millisToFriendlyDuration(legend.matchtime)}></UserStat>
                        <UserStat label="XP" value={(legend.xp/1000).toFixed()+'K'}></UserStat>
                        <UserStat label="Ranked Matches" value={(legend.rankedMatches||0).toString()}></UserStat>
                        <UserStat label="Ranked Wins" value={(legend.rankedWins||0).toString()}></UserStat>
                        <UserStat label="Peak Rating" value={(legend.peakRating||0).toString()}></UserStat>
                    </div>
                </div>
            })
        }
    </div>
}
