import React, { useMemo } from 'react';
import './shared/styles/main.scss';
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {Menu, Image} from "antd";
import AllLegendsPage from "./pages/AllLegendsPage";
import NotFoundPage from "./pages/NotFoundPage";
import PlayerStatsPage from "./pages/PlayerStatsPage";
import PlayerStatsDetailsPage from "./pages/PlayerStatsDetailsPage";
import LoadingPage from "./pages/LoadingPage";
import { useStoreSelector } from "./shared/store";
import logo from "./shared/images/brawlhalla-logo.png";
import LoadingBar from "./shared/components/LoadingBar";
import packageJson from '../package.json';

export default function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const finishedLoading = useStoreSelector(s => s.main.finishedLoading);

    const menus = useMemo(() => [
        { key: 'legends', label: 'Legends' },
        { key: 'playerstats', label: 'Player Stats' }
    ], []);

    if (!finishedLoading) return <LoadingPage />

    const currentMenuKey = location.pathname.split('/')[1] || 'legends';

    return (
        <div id="layout">
            <div className="header">
                <div className="logo-wrapper">
                    <Image id="brawlhalla-logo" src={logo} preview={false} alt="Brawlhalla Logo" />
                </div>
                <div className="nav-bar">
                    <Menu
                        mode="horizontal"
                        selectedKeys={[currentMenuKey]}
                        items={menus}
                        style={{ flex: 1, minWidth: 0 }}
                        onClick={(e) => navigate(`/${e.key}`)}
                    />
                </div>
            </div>
            <div className="content">
                <LoadingBar/>
                <div style={{ maxWidth: 1000, marginInline: 'auto' }}>
                    <Routes>
                        <Route path="legends" element={<AllLegendsPage/>}/>
                        <Route path="playerstats" element={<PlayerStatsPage/>}/>
                        <Route path="playerstats/:brawlhallaId" element={<PlayerStatsDetailsPage/>}/>
                        <Route path="/" element={<Navigate replace to="legends"/>}/>
                        <Route path="*" element={<NotFoundPage/>}/>
                    </Routes>
                </div>
            </div>
            <div className="footer">
                <div>Brawlverse is an independent project and is not affiliated with or endorsed by Blue Mammoth Games.</div>
                <div>&copy; {new Date().getFullYear()} <b>Brawlverse</b>. Version: {packageJson.version}</div>
            </div>
        </div>
    )
}
