import {ImgHTMLAttributes, useMemo} from "react";

interface RatingBannerProps extends ImgHTMLAttributes<HTMLImageElement> {
    rating: number;
}

export default function RankedBanner({rating, ...props}: RatingBannerProps) {
    const getBanner = (rating: number): string => {
        if (rating < 1000) return "Banner_Rank_Tin.png";
        else if (rating < 1500) return "Banner_Rank_Silver.png";
        else if (rating < 2000) return "Banner_Rank_Gold.png";
        else if (rating < 2500) return "Banner_Rank_Platinum.png";
        else if (rating < 3000) return "Banner_Rank_Diamond.png";
        else return "Banner_Rank_Valhallan.png";
    };
    const imagePath = useMemo(() => `/images/ranked-banners/${getBanner(rating)}`, [rating]);


    return <img src={imagePath} alt={`Rank banner for rating: ${rating}`} {...props}/>
}
