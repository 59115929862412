

export function millisToFriendlyDuration(ms: number): string {
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(ms / 3600000); // 1 hour = 3600000 milliseconds
    const minutes = Math.floor((ms % 3600000) / 60000); // 1 minute = 60000 milliseconds
    const seconds = Math.floor((ms % 60000) / 1000); // 1 second = 1000 milliseconds

    // Build the result string based on the presence of non-zero values
    let result = '';

    if (hours > 0) {
        result += `${hours}h `;
    }
    if (minutes > 0 || (hours > 0 && seconds > 0)) { // Include minutes if there are hours or seconds
        result += `${minutes}m `;
    }
    if (seconds > 0 || result === '') { // Include seconds if there are no minutes/hours or if it's the only unit
        result += `${seconds}s`;
    }

    return result.trim();
}
