import React, {useState} from "react";
import {message, Tag, Typography, Input, Spin} from "antd";
import {useNavigate} from "react-router-dom";
import {RecentlySearchedPlayer} from "../shared/types/local-storage.type";
import {getBrawlhallaId} from "../shared/services/brawlhalla.service";
import {useLocalStorage} from "@mralbelo/react-hooks";
import {useDispatch} from "react-redux";
import {setLoading} from "../shared/store/reducers/loading.reducer";

const {Text} = Typography;
const {Search} = Input;

export default function PlayerStatsPage() {
    const [searching, setSearching] = useState(false);
    const [recentPlayers] = useLocalStorage<{ [key: number]: RecentlySearchedPlayer }>(
        "recently-searched-players", {}
    );
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();

    function isValidSteamVanityUrl(url: string): boolean {
        const pattern = /^[a-zA-Z0-9]{3,17}$/;
        return url !== null && url.match(pattern) !== null;
    }

    async function onSearch(vanityUrl: string) {
        const messageKey = "player-search";

        if (!isValidSteamVanityUrl(vanityUrl)) {
            messageApi.error({content: "Please enter a valid steam username", duration: 2.5});
            return;
        }

        messageApi.loading({key: messageKey, content: "Searching for player..."});
        setSearching(true);
        dispatch(setLoading(true));

        try {
            const brawlhallaId: string = await getBrawlhallaId(vanityUrl);
            navigate(`${brawlhallaId}`);
        } catch (error) {
            messageApi.error({
                key: messageKey,
                content: "Failed to retrieve data. Please try again later.",
                duration: 2.5
            });
        } finally {
            messageApi.destroy(messageKey);
            setSearching(false);
            dispatch(setLoading(false));
        }
    }

    return (
        <>
            {contextHolder}
            {
                (searching) ?
                    <div style={{textAlign: 'center'}}><Spin style={{margin: "10px 0"}}/></div>:
                    <>
                        <Search
                            style={{marginTop: 25}}
                            type="text"
                            pattern="[a-zA-Z0-9]{3,17}"
                            addonBefore="https://steamcommunity.com/id/"
                            placeholder="username"
                            onSearch={onSearch}
                            allowClear
                            disabled={searching}
                            aria-label="Search by SteamID64"
                        />
                        {!!Object.keys(recentPlayers).length && (
                            <div style={{marginTop: 25}}>
                                <Text style={{display: 'block'}}>Recently Searched:</Text>
                                {Object.values(recentPlayers).map(player => (
                                    <Tag
                                        key={player.id}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => navigate(player.id.toString())}
                                        aria-label={`Navigate to ${player.name}'s stats`}
                                    >
                                        {player.name}
                                    </Tag>
                                ))}
                            </div>
                        )}
                    </>
            }
        </>
    );
}
