import React, {useCallback, useMemo, useState} from "react";
import {Legend} from "../shared/types/legends.type";
import {Drawer, Select} from "antd";
import LegendComponent from "../shared/components/Legend";
import LegendPage from "./LegendPage";
import {useStoreSelector} from "../shared/store";

export default function AllLegendsPage() {
    const legends = useStoreSelector(s => s.main.legends);
    const [selectedLegend, setSelectedLegend] = useState<Legend | undefined>(undefined);
    const weapons = useMemo(() => {
        return Array.from(new Set(legends.flatMap(l => [l.weapon_one, l.weapon_two])));
    }, [legends]);
    const [selectedWeapons, setSelectedWeapons] = useState<string[]>([]);

    const filteredLegends = useMemo(() => {
        // Return all legends if no weapons are selected
        if (!selectedWeapons.length) return legends;
        // Filter logic based on the number of selected weapons
        return legends.filter(legend => {
            if (selectedWeapons.length === 1) {
                return selectedWeapons.includes(legend.weapon_one) || selectedWeapons.includes(legend.weapon_two);
            }
            return selectedWeapons.includes(legend.weapon_one) && selectedWeapons.includes(legend.weapon_two);
        });
    }, [legends, selectedWeapons]);

    const handleWeaponFilterChange = useCallback((_: any, sw: { value: string; label: string } | Array<{
        value: string;
        label: string
    }>) => {
        const newWeapons = Array.isArray(sw) ? sw.map(w => w.value) : [sw.value];
        if (newWeapons.length > 2) {
            return;
        }
        setSelectedWeapons(newWeapons);
    }, []);

    return (
        <>
            <div className="filters-container">
                <Select
                    style={{width: 250}}
                    mode="multiple"
                    placeholder="Filter by Weapons"
                    options={weapons.map(w => ({value: w, label: w}))}
                    onChange={handleWeaponFilterChange}
                    value={selectedWeapons}
                />
            </div>
            <div className="legends-container">
                {filteredLegends.map((legend, index) => (
                    <LegendComponent key={index} legend={legend} onClick={() => setSelectedLegend(legend)}/>
                ))}
            </div>
            <Drawer
                open={!!selectedLegend}
                headerStyle={{display: 'none'}}
                placement="right"
                onClose={() => setSelectedLegend(undefined)}
            >
                {selectedLegend && <LegendPage legend={selectedLegend}/>}
            </Drawer>
        </>
    );
}
